import React from "react";
import { PageProps } from "gatsby";
import styled from "styled-components";

import SEO from "../components/SEO";
import { H1 } from "../components/Text";
import { PaddingContainer } from "../components/Containers";

const InfoContainer = styled.div`
  max-width: 64ch;
  display: grid;
  gap: 2rem 0;
`;

const EnglishPaymentFailedPage: React.FC<PageProps> = ({ location }) => (
  <>
    <SEO title="Payment failed" lang="en" pathName={location.pathname} />
    <PaddingContainer>
      <H1>Payment failed</H1>
      <InfoContainer>
        <p>
          Unfortunately the payment was not successful and your order has not
          been placed.
        </p>
      </InfoContainer>
    </PaddingContainer>
  </>
);

export default EnglishPaymentFailedPage;
